import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

function StepImageMultiFileUploader_V4({ row, setRow }) {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(uuidv4());

    /**
     * 클라우디나리 업로드 다이렉트로 업로드
     */
    async function handleFileChange(e) {
        try {
            setLoading(true);
            const files = e.target.files;
            const uploadPromises = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const formData = new FormData();

                formData.append("file", file);
                formData.append("upload_preset", "mzbjpjdi");
                formData.append("folder", "rcp_v4");

                // 각 파일 업로드를 Promise로 생성하고 배열에 추가
                const uploadPromise = axios({
                    url: `https://api.cloudinary.com/v1_1/dse98rb39/image/upload`,
                    method: "POST",
                    data: formData,
                }).then(({ data }) => {
                    return data.secure_url;
                });
                uploadPromises.push(uploadPromise);
            }

            // 모든 파일 업로드가 완료될 때까지 기다림
            const urls = await Promise.all(uploadPromises);

            // 모든 업데이트를 한 번에 처리
            const newSteps = [...row.steps];
            Array.from(files).forEach((f, i) => {
                const filename = f.name;
                const tmp = filename.split(".");
                const tmp2 = tmp[0].split("-");
                const stepIndex = tmp2[0] - 1;
                const filenameSeq = tmp2[1] - 1;
                newSteps[stepIndex][`filename${filenameSeq}`] = urls[i];
            });

            console.log(newSteps);

            setRow({ ...row, steps: newSteps });
        } catch (error) {
            console.error("File upload error:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <input id={`file_${id}`} type="file" multiple onChange={handleFileChange} style={{ display: "none" }} />
            {loading ? (
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <label
                    htmlFor={`file_${id}`}
                    className="d-flex align-items-center btn btn-sm btn-primary"
                    style={{ fontSize: "12px", height: "24px" }}
                >
                    스텝사진일괄등록
                </label>
            )}
        </>
    );
}

export default StepImageMultiFileUploader_V4;
